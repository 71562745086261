import React from "react";

import {
  Alert,
  Row,
  Col,
  Divider,
  Card,
  Select,
  Descriptions,
  Typography,
} from "antd";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    title: "Apixaban",
    indicatieOne: "2 dd 2,5 mg",
    indicatieTwo:
      "2 dd 5 mg - 2 dd 2,5 mg bij aanwezigheid van ≥ 2 factoren: serum creat. > 133 µmol/l of leeftijd ≥ 80 jaar of gewicht ≤ 60 kg",
    indicatieThree:
      "2 dd 10 mg gedurende de eerste 7 dagen; daarna 2 dd 5 mg - Na 6 maanden 2 dd 2,5 mg (secundaire preventie)",
    alertWarning:
      "Apixaban kan worden gebruikt bij patiënten met een BMI > 40 kg/m2 of een gewicht van > 120 kg (KA Martin, JTH, augustus 2021, Volume 19, Issue 8;1874-1882)",
  },
  {
    index: 1,
    title: "Dabigatran",
    indicatieOne:
      "1 dd 220 mg - 1 dd 150 mg bij creat. klaring 30 tot 50 ml/min of bij leeftijd ≥ 75 jaar",
    indicatieTwo:
      "2 dd 150 mg - Overweeg 2 dd 110 mg bij creat. klaring 30-50 ml/min of bij leeftijd 75 tot 80 jaar - Bij comedicatie verapamil of leeftijd ≥ 80 jaar 2 dd 110 mg",
    indicatieThree:
      "Start met LMWH, na minimaal 5 dagen LMWH verder gaan met 2 dd 150 mg - Overweeg 2 dd 110 mg bij creat. klaring 30 tot 50 ml/min of bij leeftijd 75 tot 80 jaar - Bij comedicatie verapamil of leeftijd ≥ 80 jaar 2 dd 110 mg",
    alertError:
      "Dabigatran moet niet gebruikt worden bij patiënten met een BMI > 40 kg/m2 of een gewicht van > 120 kg (KA Martin, JTH, augustus 2021, Volume 19, Issue 8;1874-1882)",
  },
  {
    index: 2,
    title: "Edoxaban",
    indicatieOne: "Geen indicatie",
    indicatieTwo:
      "1 dd 60 mg - 1 dd 30 mg bij creat. klaring 30-50 ml/min of gewicht ≤ 60 kg",
    indicatieThree:
      "Start met LMWH, na minimaal 5 dagen LMWH verder gaan met 1 dd 60 mg - 1 dd 30 mg bij creat. klaring 30-50 ml/min of gewicht ≤ 60 kg",
    alertError:
      "Edoxaban moet niet gebruikt worden bij patiënten met een BMI > 40 kg/m2 of een gewicht van > 120 kg (KA Martin, JTH, augustus 2021, Volume 19, Issue 8;1874-1882)",
  },
  {
    index: 3,
    title: "Rivaroxaban",
    indicatieOne: "1 dd 10 mg",
    indicatieTwo: "1 dd 20 mg - 1 dd 15 mg bij creat. klaring 30 tot 49 ml/min",
    indicatieThree:
      "2 dd 15 mg gedurende eerste drie weken; daarna 1 dd 20 mg - Overweeg bij creat. klaring 30 tot4 9 ml/min bij ingeschat hoog bloedingsrisico onderhoudsdosering 1 dd 15 mg - Na 6 maanden 1 dd 10 mg (secundaire preventie)",
    indicatieFour:
      "2 dd 2,5 mg, in combinatie met acetylsalicylzuur met of zonder clopidogrel",
    indicatieFive: "2 dd 2,5 mg, in combinatie met acetylsalicylzuur",
    alertWarning:
      "Rivaroxaban kan worden gebruikt bij patiënten met een BMI > 40 kg/m2 of een gewicht van > 120 kg (KA Martin, JTH, augustus 2021, Volume 19, Issue 8;1874-1882)",
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <Card>
        <Row>
          <Col xs={24}>
            <Title level={4}>DOAC:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              autoFocus
              defaultValue="Apixaban"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een DOAC.."
            >
              {steps.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Indicaties:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Tromboseprofylaxe na electieve heup- en knie vervangingsoperaties">
                {steps[current].indicatieOne}
              </Descriptions.Item>
              <Descriptions.Item label="Preventie van herseninfarct en systemische embolie bij non-valvulair atriumfibrilleren">
                {steps[current].indicatieTwo}
              </Descriptions.Item>
              <Descriptions.Item label="Diep veneuze trombose en longembolie en preventie van recidief diep veneuze trombose en longembolie">
                {steps[current].indicatieThree}
              </Descriptions.Item>
              {steps[current].indicatieFour && (
                <Descriptions.Item label="Preventie van atherotrombotische complicaties na een acuut coronair syndroom met verhoogde cardiale biomarkers">
                  {steps[current].indicatieFour}
                </Descriptions.Item>
              )}
              {steps[current].indicatieFive && (
                <Descriptions.Item label="Preventie van atherotrombotische complicaties bij coronaire hartziekte of symptomatisch perifeer arterieel vaatlijden met veel kans op ischemische voorvallen">
                  {steps[current].indicatieFive}
                </Descriptions.Item>
              )}
            </Descriptions>
            {steps[current].alertWarning ? (
              <Alert
                message={steps[current].alertWarning}
                type="warning"
                showIcon
                style={{ margin: "16px 0 0 0" }}
              />
            ) : null}
            {steps[current].alertError ? (
              <Alert
                message={steps[current].alertError}
                type="error"
                showIcon
                style={{ margin: "16px 0 0 0" }}
              />
            ) : null}
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Stepper;

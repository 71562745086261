import React from "react";
import TrackVisibility from "react-on-screen";
import { BrowserView, CustomView, isMobileOnly } from "react-device-detect";
import { Card, Col, Divider, Typography } from "antd";
import LinksButtons from "../links/button/LinksButtons";
import TextLoop from "react-text-loop";
import NodeArrowAnchors from "./components/NodeArrowAnchors";

const { Title } = Typography;

export default function NodeCardActiveWithGrid({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  links,
  nodeId,
  nodeTitle,
  onNext,
  options,
  offset,
  span,
  startDemo,
  language,
  useStartDemoProp,
}) {
  const textDivider =
    language === "nl" ? "Beschikbare optie(s)" : "Option(s) available";
  const textDividerAnimation =
    language === "nl"
      ? "Selecteer een optie om door te gaan"
      : "Select an option to proceed";

  return (
    <Col
      className="node-wrapper"
      id={nodeId}
      key={nodeId}
      offset={offset}
      span={span}
      alignSelf="top"
      style={{ zIndex: "1000" }}
    >
      <Card
        title={nodeTitle}
        style={{
          border: "3px solid #EBD247",
          borderRadius: "4px",
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
          cursor: "help",
        }}
        className="node-active"
        actions={<LinksButtons links={links} onNext={onNext} size="large" />}
      >
        <BrowserView>
          {!options && <>{children}</>}
          {extraContent && <>{extraContent}</>}
          {options && <>{options}</>}
          {!disableDefaultOptionsButtons && (
            <>
              <Divider
                orientation="left"
                plain
                style={{
                  margin: "0px 0 10px 0",
                  fontSize: "12px",
                  borderColor: "#ced4da",
                }}
              >
                <TextLoop interval={5000}>
                  <>{textDivider}:</>
                  <>{textDividerAnimation}:</>
                </TextLoop>
              </Divider>
              <LinksButtons links={links} onNext={onNext} />
            </>
          )}
        </BrowserView>
        <CustomView condition={isMobileOnly}>
          {!useStartDemoProp > 0 && (
            <TrackVisibility offset={50}>
              {({ isVisible }) =>
                isVisible ? (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        left: "0",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "100%",
                        padding: "24px",
                        boxShadow: "-6px -6px 10px rgba(17, 17, 26, 0.1)",
                        borderTop: "2px solid #EBD247",
                      }}
                    >
                      <Title level={4}>{nodeTitle}</Title>
                      {!options && <>{children}</>}
                      {extraContent && <>{extraContent}</>}
                      {options && <>{options}</>}
                      {!disableDefaultOptionsButtons && (
                        <>
                          <Divider
                            orientation="left"
                            plain
                            style={{
                              margin: "16px 0 10px 0",
                              fontSize: "12px",
                              borderColor: "#ced4da",
                            }}
                          >
                            <TextLoop interval={5000}>
                              <>{textDivider}:</>
                              <>{textDividerAnimation}:</>
                            </TextLoop>
                          </Divider>
                          <LinksButtons links={links} onNext={onNext} />
                        </>
                      )}
                    </div>
                  </>
                ) : null
              }
            </TrackVisibility>
          )}
          {useStartDemoProp && startDemo > 0 && (
            <TrackVisibility offset={50}>
              {({ isVisible }) =>
                isVisible ? (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        left: "0",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "100%",
                        padding: "24px",
                        boxShadow: "-6px -6px 10px rgba(17, 17, 26, 0.1)",
                        borderTop: "2px solid #EBD247",
                      }}
                    >
                      <Title level={4}>{nodeTitle}</Title>
                      {!options && <>{children}</>}
                      {extraContent && <>{extraContent}</>}
                      {options && <>{options}</>}
                      {!disableDefaultOptionsButtons && (
                        <>
                          <Divider
                            orientation="left"
                            plain
                            style={{
                              margin: "16px 0 10px 0",
                              fontSize: "12px",
                              borderColor: "#ced4da",
                            }}
                          >
                            <TextLoop interval={5000}>
                              <>{textDivider}:</>
                              <>{textDividerAnimation}:</>
                            </TextLoop>
                          </Divider>
                          <LinksButtons links={links} onNext={onNext} />
                        </>
                      )}
                    </div>
                  </>
                ) : null
              }
            </TrackVisibility>
          )}
        </CustomView>
      </Card>
      <NodeArrowAnchors nodeId={nodeId} />
    </Col>
  );
}

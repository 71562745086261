import React from "react";
import NodeCardActive from "../../node/NodeCardActive.js";
import NodeCardEnd from "../../node/NodeCardEnd.js";
import NodeCollapseWithArrow from "../../node/NodeCollapseWithArrow.js";

export default function Node({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  isEndNode,
  isLastOnPath,
  isOnPath,
  language,
  links,
  linksActive,
  nodeId,
  nodeTitle,
  onGoTo,
  onNext,
  onReset,
  options,
}) {
  const onNodeGoTo = () => onGoTo(nodeId);

  if (!isOnPath) {
    return null;
  } else if (isOnPath && !isLastOnPath) {
    return (
      <NodeCollapseWithArrow
        children={children}
        isLastOnPath={isLastOnPath}
        language={language}
        links={links}
        linksActive={linksActive}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        onNext={onNext}
        onNodeGoTo={onNodeGoTo}
      />
    );
  } else if (isEndNode) {
    return (
      <NodeCardEnd
        children={children}
        language={language}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        onReset={onReset}
      />
    );
  } else if (isLastOnPath) {
    return (
      <NodeCardActive
        children={children}
        disableDefaultOptionsButtons={disableDefaultOptionsButtons}
        extraContent={extraContent}
        language={language}
        links={links}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        onNext={onNext}
        onReset={onReset}
        options={options}
      />
    );
  }
}

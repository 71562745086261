import React from "react";
import { List, Typography } from "antd";

const { Paragraph, Text, Title } = Typography;

const ContentListComponent = ({ header, items }) => {
  return (
    <>
      <List
        size="small"
        header={header}
        bordered
        dataSource={items}
        renderItem={(item) => <List.Item>{item}</List.Item>}
        style={{ marginBottom: "15px" }}
      />
    </>
  );
};

export default ContentListComponent;

import React from "react";

import {
  Alert,
  Row,
  Col,
  Divider,
  Card,
  Select,
  Descriptions,
  Typography,
} from "antd";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    naam: "Kors-Walraven",
    hoofdfunctie: "Beleidsadviseur, De Hart&Vaatgroep",
    nevenwerkzaamheden: "nvt",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel:
      "Uitsluitend direct / inhoudelijk werkgerelateerd: o.a. participatie in programma KIDZ van de NPCF",
    overig: "nvt",
    getekend: "27-6-2017",
  },
  {
    index: 1,
    naam: "Hemels",
    hoofdfunctie:
      "- Cardioloog-elektrofysioloog Rijnstate Ziekenhuis (4 dagen) en Radboudumg (1 dag) - Programmaleider NVVC Connect Atrium fibrilleren (0,5 dag)",
    nevenwerkzaamheden:
      "- Principal investigator (samen met prof. M.V. Huisman) van het Dutch AF onderzoeks- en registratieproject namens de NVVC en in opdracht van VWS/ZonMw. Er gaat een vergoeding naar de vakgroep cardiologie in Rijnstate i.v.m. mijn werkzaamheden voor dit project.- Associate editor van Netherlands Heart Journal, géén vergoeding.- Onderwijsactiviteiten voor Medisch Specialisten, huisartsen, (ziekenhuis)apothekers en arts-assistenten/physician assistans, betreft urenvergoeding.- Adviesraadfunctie (incidenteel); 2017 - Local principal investigator van diverse patiëntgebonden studies. (hartrimtestoornissen gerelateerd) in het Rijnstate Ziekenhuis, géén persoonlijke vergoeding hiervoor.",
    belangen: "nvt",
    relaties: "nvt",
    extern:
      "- Zie ook bij nevenwerkzaamheden op pagina 2; Principal Investigator van DutchAP namens de NVVC en in opdracht van VWS/ZonMw. - Zie ook bij nevenwerkzaamheden op pagina 2; Local principal investigator van diverse patiëntgebonden studies in het Rijnstate Ziekenhuis. Géén persoonlijke vergoeding hiervoor.",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "14-7-2017",
  },
  {
    index: 2,
    naam: "Huisman",
    hoofdfunctie: "Internist LUMC Leiden",
    nevenwerkzaamheden:
      "Voorzitter Nationale richtlijn Antitrombotisch Beleid Federatie Medisch Specialisten Voorzitter nationaal kennisplatform Antistolling in oprichting Principal investigator (samen met Dr MEW Hemels) van het Dutch AF onderzoeks- en registratieproject ZonMw. Er gaat een vergoeding naar de afdeling Trombose en Hemostase van het LUMC",
    belangen:
      "Spreekbeurten namens en betaalde adviezen aan Pfizer-BMS, Boehringer Ingelheim, Aspen, Bayer Health Care, Daiichi-Sankyo; gelden komen ten gunste van het LUMC",
    relaties: "nvt",
    extern:
      "Unrestricted grants voor wetenschappelijk onderzoek (Pfizer-BMS, Boehringer Ingelheim, Daiichi-Sankyo)",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "19-7-2017, aanpassingen 23-1-2020",
  },
  {
    index: 3,
    naam: "Snoeijen",
    hoofdfunctie:
      "Huisarts, maatschap vd Boomen/ Snoeijen/ Zwartendijk 3 dagen per week",
    nevenwerkzaamheden:
      "Universiteit van Maastricht, huisartsgeneeskunde, coördinator differentiatie hart en vaatziekten 0 uren contract (betaald) - Niet gesponsorde scholingen (betaald)",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "24-7-2017, aanpassingen 27-1-2020",
  },
  {
    index: 4,
    naam: "Faber",
    hoofdfunctie: "Internist-hematoloog Rode Kruis Ziekenhuis Beverwijk",
    nevenwerkzaamheden:
      "Medisch leider Trombosediensten RKZ en Starlet (Alkmaar - Den Helder), betaald",
    belangen:
      "Spreekbeurten namens en betaalde adviezen aan Pfizer-BMS, Boehringer Ingelheim, Aspen, Bayer Health Care, Daiichi-Sankyo; gelden komen ten gunste van het LUMC",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "26-7-2017",
  },
  {
    index: 5,
    naam: "Geersing",
    hoofdfunctie:
      "Huisarts in Amsterdam bij praktijk Buitenhof, onderdeel van het OLVG te Amsterdam. Ik werk hier twee dagen in de klinische praktijk",
    nevenwerkzaamheden:
      "- Onderzoeker bij het Julius Centrum van het UMC Utrecht. Hier doe ik onderzoek naar trombosezorg, specifiek gericht op kennis voor de eerste lijn. Bij het UMC Utrecht heb ik een aanstelling voor 20 uur per week - Medisch adviseur trombosezorg bij Saltro, voor ongeveer een dagdeel per twee weken - Bestuurslid bij de Federatie Nederlandse Trombosediensten",
    belangen: "Ik heb geen persoonlijke financiële belangen",
    relaties: "nvt",
    extern:
      "Ik ben betrokken bij verschillende onderzoeken op het gebied van trombose en antistollingszorg, grotendeels gefinancierd door ZonMw en NWO, onder andere een persoonlijke Veni-subsidie en subsidies via het programma Goed Gebruik Geneesmiddelen, Doelmatigheid en Huisartsgeneeskunde. Aanvullende financiering wordt verkregen via unrestricted grants van Boehringer-Ingelheim (75K EUR), Bayer Healtcare (75K EUR), Roche (100K EUR) en BMS Pfizer (75K EUR)",
    intellectueel: "Geen intellectuele belangen",
    overig: "nvt",
    getekend: "22-8-2017",
  },
  {
    index: 6,
    naam: "Brouwer",
    hoofdfunctie: "Cardioloog, Radboudumc",
    nevenwerkzaamheden:
      "Afgevaardigde van de NVVC bij: - LSKA (Landelijke Standaard Ketzorg Antistorling), onbetaald - WR (Wetenschappelijke Raad van de Nederlandse Reanimatie Raad), onbetaald",
    belangen:
      "De research-afdeling cardiologie heeft de verschillende projecten financiële ondersteuning ontvangen van: Astra Zeneca, BMS-Pfizer, Boehringer Ingelheim, Bayer, Daiichi Sankyo",
    relaties: "nvt",
    extern:
      "- Dutch AF registry - FRAIL randomized trial - REDUCE trial on DAPT duration - REDUCE MVI trial",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "8-11-2017",
  },
  {
    index: 7,
    naam: "Auwerda",
    hoofdfunctie: "Beleidsadviseur Harteraad",
    nevenwerkzaamheden:
      "Afgevaardigde van de NVVC bij: - LSKA (Landelijke Standaard Ketzorg Antistorling), onbetaald - WR (Wetenschappelijke Raad van de Nederlandse Reanimatie Raad), onbetaald",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "29-8-2019",
  },
  {
    index: 8,
    naam: "Horikx",
    hoofdfunctie:
      " - Aptoheker - Coördinator helpdesk Geneesmiddel Informatie Centrum - Werkgever: KNMP",
    nevenwerkzaamheden:
      "Allen onbetaald - Commissie Interacterende medicatie cumarines - Richtlijncommissie euthanasie KNMG/KNMP - Platform Medicatieveiligheid in de Care sector -Taakgroep gepast gebruik Opioïden",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "Ik zit er namens de beroepsorganisatie",
    overig: "nvt",
    getekend: "7-3-2017, aanpassing 24-1-2020",
  },
  {
    index: 9,
    naam: "Renes",
    hoofdfunctie: "RadboudUMC, anesthesioloog-pijnarts",
    nevenwerkzaamheden: "nvt",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "29-6-2017",
  },
  {
    index: 10,
    naam: "Wiersema",
    hoofdfunctie:
      "- Vaatchirurg fulltime Westfriesgasthuis, loondienst - Onderzoeksleider bij ACT meting bij heparine gebruik bij niet-cardiale vasculaire interventies. (CAPPA, MANCO en ACTION-1 studie)",
    nevenwerkzaamheden: "Vaatchirurgie in de volle breedte, betaald",
    belangen: "nvt",
    relaties: "nvt",
    extern:
      "ja, ACTION-1: ZonMw, Dijklander, AUMCs en Medtronic: waarde ACR bepaling bij open AAA, internationale multicenter RCT",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "7-4-2017, aanpassing 23-1-2020",
  },
  {
    index: 11,
    naam: "Wouts",
    hoofdfunctie: "AIOS SEH LUMC",
    nevenwerkzaamheden: "nvt",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "17-7-2017",
  },
  {
    index: 12,
    naam: "Bosma",
    hoofdfunctie: "Ziekenhuisapotheker",
    nevenwerkzaamheden:
      "Antistolling dossier werkgroep voorzitter Chipsoft Hix, onbetaald",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "20-7-2017",
  },
  {
    index: 13,
    naam: "Jong, de",
    hoofdfunctie: "NHG wetenschappelijk medewerker (16u)",
    nevenwerkzaamheden:
      "AMC Huisartsopleiding teamleider stagejaar 20 uur (betaald)",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "25-7-2017",
  },
  {
    index: 14,
    naam: "Rozema",
    hoofdfunctie:
      "MKA-chirurg, Hoogleraar Orale Geneeskunde,ACTA/Amsterdam UMC",
    nevenwerkzaamheden:
      "Voorzitter Richtlijn Ontwikkelcie. KIMO Bloedige ingrepen in de mondzorg bij patiënten die antitrombotica gebruiken-vacatiegeld - Directeur Ned. Tijdschrift voor Tandheelkunde- vacatiegeld - Voorzitter Richtlijnontwikkel Cie KIMO: AB beleid in de monzorg - vacatiegeld",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "7-4-2017, aanpassing 23-1-2020",
  },
  {
    index: 15,
    naam: "Tushuizen",
    hoofdfunctie: "MDL-arts",
    nevenwerkzaamheden: "nvt",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "7-6-2017",
  },
  {
    index: 16,
    naam: "Beek, van",
    hoofdfunctie: "Klinisch geriater farmacoloog",
    nevenwerkzaamheden:
      "Alle functies onbetaald: - Lid antistollingscommissie Catharina ziekenhuis - Klinisch farmacoloog in opleiding - Bestuur VMSD Catharina ziekenhuis Eindhoven - Lid advies werkgroep Federatie medisch specialisten, alleen vacatiegelden - Lid gezondheidsraad commissie vaccinaties",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "10-3-2017, aanpassing 23-1-2020",
  },
  {
    index: 17,
    naam: "Dijk, van",
    hoofdfunctie:
      "- Neuroloog in Radboudumc (0,6fte) - Voorzitter stafconventbestuur Radboudumc (0,3fte) - Voorzitter Nederlandse Verengiging voor Neurologie (0,1fte)",
    nevenwerkzaamheden: "Bestuurslid Dr. Jan Meerwaldt stichting (onbezoldigd)",
    belangen: "nvt",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "10-12-2019",
  },
  {
    index: 18,
    naam: "Ten Cate",
    hoofdfunctie:
      "- Internist-vasculair geneeskundige MUMC+ 1,0 fte - Directeur Trombose Expertise Centrum MUMC+ (valt onder bovenstaande)",
    nevenwerkzaamheden:
      "- VZ Bestuur Federatie Nederlandse Trombosediensten, FNT (onbezoldigd) - VZ Trombosecie MUMC+ (onbezoldigd) - VZ Stichting STOL (onbezoldigd) - Lid Stichting Perfusie (vacatie vergoeding)",
    belangen:
      "- Aandeelhouder Coagulation Profile - Consultant Alveron Pharma (bezoldigd) - Adviescommissie ad hoc t.v. Bayer, Pfizer, Leo farma (bezoldigd)",
    relaties: "nvt",
    extern: "nvt",
    intellectueel: "nvt",
    overig: "nvt",
    getekend: "14-1-2020",
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <Card>
        <Row>
          <Col xs={24}>
            <Title level={4}>Achternaam:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              autoFocus
              defaultValue="Kors-Walraven"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een persoon:"
            >
              {steps.map((item) => (
                <Option value={item.index} key={item.naam} title={item.naam}>
                  {item.naam}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Belangenverklaring:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Achternaam">
                {steps[current].naam}
              </Descriptions.Item>
              <Descriptions.Item label="Hoofdfunctie">
                {steps[current].hoofdfunctie}
              </Descriptions.Item>
              <Descriptions.Item label="Nevenwerkzaamheden">
                {steps[current].nevenwerkzaamheden}
              </Descriptions.Item>
              <Descriptions.Item label="Persoonlijke financiële belangen">
                {steps[current].belangen}
              </Descriptions.Item>
              <Descriptions.Item label="Persoonlijke relaties">
                {steps[current].relaties}
              </Descriptions.Item>
              <Descriptions.Item label="Extern gefinancieerd onderzoek">
                {steps[current].extern}
              </Descriptions.Item>
              <Descriptions.Item label="Intellectuele belangen en reputatie">
                {steps[current].intellectueel}
              </Descriptions.Item>
              <Descriptions.Item label="Overige belangen">
                {steps[current].overig}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Stepper;

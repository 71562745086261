import React from "react";
import { Col, Button, Tooltip, Divider, Typography, Collapse } from "antd";
import ArrowsActiveBottomTop from "../links/arrow/ArrowsActiveBottomTop";
import { useXarrow } from "react-xarrows";
import LinksButtonsActive from "../links/button/LinksButtonsActive";
import { EditOutlined } from "@ant-design/icons";
import NodeArrowAnchors from "./components/NodeArrowAnchors";
const { Text } = Typography;
const { Panel } = Collapse;

export default function NodeCollapseWithArrow({
  children,
  isLastOnPath,
  links,
  linksActive,
  nodeId,
  nodeTitle,
  onNext,
  onNodeGoTo,
  language,
}) {
  const textDivider = language === "nl" ? "Geselecteerd" : "Selected";
  const textButtonEdit = language === "nl" ? "Wijzigen" : "Edit";
  const textButtonEditTooltip =
    language === "nl" ? "Wijzig deze stap" : "Edit this node";

  return (
    <Col
      id={nodeId}
      key={nodeId}
      span={20}
      offset={2}
      style={{
        marginBottom: "40px",
        padding: "0px",
      }}
    >
      <Collapse accordion expandIconPosition="left" bordered={false}>
        <Panel
          forceRender="true"
          header={<Text style={{ fontWeight: "500" }}>{nodeTitle}</Text>}
          onChange={useXarrow()}
          extra={
            <>
              <Tooltip placement="top" title={textButtonEditTooltip}>
                <Button size="small" onClick={onNodeGoTo}>
                  <EditOutlined /> {textButtonEdit}
                </Button>
              </Tooltip>
            </>
          }
          style={{
            backgroundColor: "white",
            border: "3px solid #40c057",
            borderRadius: "4px",
            boxShadow: "0 2px 3px rgba(0, 0, 0, 0.1)",
          }}
        >
          {children}
          <Divider
            orientation="left"
            plain
            style={{
              margin: "0",
              fontSize: "10px",
              borderColor: "#40c057",
            }}
          >
            {textDivider}
          </Divider>
          <LinksButtonsActive
            links={links}
            linksActive={linksActive}
            onNext={onNext}
          />
        </Panel>
        <ArrowsActiveBottomTop
          arrowColorActive="#40c057"
          arrowColorInactive="#adb5bd"
          gridBreak="90%"
          isLastOnPath={isLastOnPath}
          links={links}
          linksActive={linksActive}
          nodeId={nodeId}
        />
        <NodeArrowAnchors nodeId={nodeId} />
      </Collapse>
    </Col>
  );
}

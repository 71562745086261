import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { Alert, Divider, Tag, Tooltip, Descriptions, Icon } from "antd";

const TableChadCalc = () => (
  <>
    <Descriptions bordered size="small">
      <Descriptions.Item span="1" label="Hartfalen">
        max 1
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        1 punt
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Hypertensie">
        max 1
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        1 punt
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Leeftijd &ge; 75 jaar">
        max 2
        <Alert
          stle={{ fontSize: "8px" }}
          message="Bij JA kan vraag over leeftijd 65 tot 75 jaar worden overgeslagen en automatisch 0 gescoord worden."
          type="info"
          showIcon
        />
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        2 punten
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Diabetes Mellitus">
        max 1
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        1 punt
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
      <Descriptions.Item
        span="1"
        label="Herseninfarct (‘stroke’), Cerebro Vasculair Accident (CVA), transient ischemic attack (TIA), arteriële tromboembolie"
      >
        max 2
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        2 punten
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
      <Descriptions.Item
        span="1"
        label="Vaatlijden (vroeger myocardinfarct, perifeer arterieel vaatlijden, aorta plaque)"
      >
        max 1
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        1 punt
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Leeftijd 65 - 75 jaar">
        max 1
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        1 punt
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Vrouwelijk geslacht">
        max 1
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Ja">
        1 punt
      </Descriptions.Item>
      <Descriptions.Item span="1" label="Nee">
        0
      </Descriptions.Item>
    </Descriptions>
  </>
);

export default TableChadCalc;

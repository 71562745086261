import { Divider, Card, Row, Col, Icon, Tag } from "antd";
import Contact from "../../../../src/components/FormContactColofon";
import * as React from 'react';
export default {
  Divider,
  Card,
  Row,
  Col,
  Icon,
  Tag,
  Contact,
  React
};
import React from "react";
import Xarrow from "react-xarrows";

const Arrows = ({
  arrowColorActive,
  arrowColorInactive,
  gridBreak,
  isLastOnPath,
  links,
  linksActive,
  nodeId,
}) => {
  return links.link.map((item) => {
    const animateDrawing = item.nodeId === isLastOnPath ? 0.2 : false;
    const color =
      item.nodeId === linksActive ? arrowColorActive : arrowColorInactive;
    const curveness = item.nodeId === linksActive ? 0.5 : 0.3;
    const headSize = item.nodeId === linksActive ? 4 : 3;
    const path = item.nodeId === linksActive ? "smooth" : "grid";
    const zIndex = item.nodeId === linksActive ? 1000 : 0;
    return (
      <Xarrow
        animateDrawing={animateDrawing}
        color={color}
        curveness={curveness}
        end={`${item.nodeId}-${item.arrowEndAnchor}`}
        endAnchor={item.arrowEndAnchor}
        gridBreak={gridBreak}
        headSize={headSize}
        key={`${item.nodeId}-${item.arrowEndAnchor}`}
        path={path}
        start={`${nodeId}-${item.arrowStartAnchor}`}
        startAnchor={item.arrowStartAnchor}
        zIndex={zIndex}
        labels={{
          middle: (
            <div
              style={{
                fontSize: "8px",
                position: "relative",
                top: "-5px",
                opacity: "0.5",
              }}
            >
              {item.text}
            </div>
          ),
        }}
        path={path}
        start={`${nodeId}-bottom`}
        startAnchor="bottom"
        zIndex={zIndex}
      />
    );
  });
};

export default Arrows;

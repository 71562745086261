import { List, Typography, Descriptions, Divider, Alert, Table } from "antd";
import ListUrlAction from "../../../../src/components/ListUrlAction";
import SelectTAR from "../../../../src/components/content/SelectTAR";
import SelectDOACIndicatie from "../../../../src/components/content/SelectDOACIndicatie";
import * as React from 'react';
export default {
  List,
  Typography,
  Descriptions,
  Divider,
  Alert,
  Table,
  ListUrlAction,
  SelectTAR,
  SelectDOACIndicatie,
  React
};
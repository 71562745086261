import React from "react";

import {
  Alert,
  Row,
  Col,
  Divider,
  Card,
  Select,
  Descriptions,
  Typography,
} from "antd";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    title: "Ongefractioneerde heparine",
    toediening: "i.v. of s.c.",
    thalf: "0.5 tot 3 uur",
    couperen:
      "Protamine: 10 mg (1000E) remt 1000 E heparine die in de laatste 2 uur i.v. zijn toegediend. Maximale dosis = 50 mg, daarna op geleide van APTT",
  },
  {
    index: 1,
    title: "Nadroparine",
    toediening: "s.c.",
    thalf: "3 tot 4 uur",
    couperen:
      "Niet of deels te antagoneren met protamine, maximale remming 25 tot 50%. 6 mg (600E) neutraliseert ongeveer 950 IE Nadroparine. Maximale dosis = 50 mg",
  },
  {
    index: 2,
    title: "Danaparoïd (Orgaran®)",
    toediening: "i.v. of s.c.",
    thalf: "25 uur",
    couperen: "-",
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <Card>
        <Row>
          <Col xs={24}>
            <Title level={4}>Protamine:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              autoFocus
              defaultValue="Ongefractioneerde heparine"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een protamince.."
            >
              {steps.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Dosering:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              border
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Toediening">
                {steps[current].toediening}
              </Descriptions.Item>
              <Descriptions.Item label="T ½">
                {steps[current].thalf}
              </Descriptions.Item>
              <Descriptions.Item label="Couperen">
                {steps[current].couperen}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Stepper;

import React from "react";

export default function NodeArrowAnchors({ nodeId }) {
  return (
    <>
      <div
        id={`${nodeId}-top`}
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "70px",
          height: "1px",
        }}
      />
      <div
        id={`${nodeId}-left`}
        style={{
          position: "absolute",
          top: "50%",
          left: "12px",
          width: "0px",
          height: "0px",
        }}
      />
      <div
        id={`${nodeId}-right`}
        style={{
          position: "absolute",
          top: "50%",
          right: "12px",
          width: "0px",
          height: "0px",
        }}
      />
      <div
        id={`${nodeId}-bottom`}
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "70px",
          height: "0px",
        }}
      />
    </>
  );
}

import React from "react";
import { Typography } from "antd";

const Text = Typography.Text;

const ListUrlAction = ({ link, linkName }) => (
  <>
    {link.length > 0 && (
      <>
        <a
          className="ant-btn ant-btn-dashed ant-btn-sm"
          href={link}
          target="_blank"
          rel="noreferrer noopener"
        >
          {linkName}
        </a>
        <Text
          copyable={{ text: link }}
          style={{ color: "rgba(0,0,0,0.45)" }}
        ></Text>
      </>
    )}
  </>
);

export default ListUrlAction;

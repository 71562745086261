import { Link } from "gatsby";
import { Collapse, Typography, Divider, Alert } from "antd";
import * as React from 'react';
export default {
  Link,
  Collapse,
  Typography,
  Divider,
  Alert,
  React
};
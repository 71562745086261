import React, { useState } from "react";
import {
  Button,
  Carousel,
  Descriptions,
  Divider,
  Result,
  Row,
  Typography,
  Alert,
} from "antd";
import ReactFromJSON from "react-from-json";
import Node from "./Nodes";
import { Link } from "gatsby";
import { Xwrapper } from "react-xarrows";
import * as Scroll from "react-scroll";
// import ABCDEApproach from "../../../client/examples/widgets/abcde-approach";
// import Alert from "../../ui/ContentAlert";
// import ContentLinkExternalFk from "../../ui/ContentLinkExternalFk.js";
// import ContentLinkExternalKf from "../../ui/ContentLinkExternalKf.js";
// import ContentLinkExternalAcuteBoekje from "../../ui/ContentLinkExternalAcuteBoekje";
// import ContentLinkExternalRldb from "../../ui/ContentLinkExternalRldb";
// import ContentListAction from "../../ui/ContentListAction";
// import IVaccess from "../../../client/examples/widgets/iv-access";
import List from "../../ui/ContentList";
import ModalContent from "../../ui/modalContent";
// import OptionsItemsPointsComponent from "../../chart/links/options/OptionsItemsPointsComponent";
// import OptionsSelectComponent from "../../chart/links/options/OptionsSelectComponent";
// import Prevalentie from "../../../client/examples/widgets/Prevalentie";
import SelectBloedRisico from "../../ui/SelectBloedRisico";
import TableTromboRisico from "../../ui/TableTromboRisico";
//
import Overview from "../../ui/Overview";
import { ExportOutlined } from "@ant-design/icons";
import ContentModalWithButtons from "../../ui/ContentModalWithButtons";
import ContentLinkExternalAcuteBoekje from "../../ui/ContentLinkExternalAcuteBoekje";
import ContentLinkExternalFk from "../../ui/ContentLinkExternalFk.js";
import ContentLinkExternalFNT from "../../ui/ContentLinkExternalFNT";
import ContentLinkExternalKf from "../../ui/ContentLinkExternalKf.js";
import ContentLinkExternalRldb from "../../ui/ContentLinkExternalRldb";
import "./styles.scss";

const { Paragraph, Text, Title } = Typography;
const scroller = Scroll.scroller;

export default function ChartCollapse({ entry, firstNodeId }) {
  const [nodePath, setNodePath] = useState([firstNodeId]);
  const [isLastOnPath, setIsLastOnPath] = useState(firstNodeId);

  const onNext = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition === -1) {
      const addNextNode = nodePath.concat([nodeId]);
      setNodePath(addNextNode);
      const nextNodeId = nodeId.toString();
      setIsLastOnPath(nextNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.1 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, 10);
        }
      }, 50);
    } else {
      onGoTo(nodeId);
    }
  };

  const onGoTo = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition !== -1) {
      const goToNode = nodePath.slice(0, nodeCurrentPosition + 1);
      setNodePath(goToNode);
      const goToNodeId = nodeId.toString();
      setIsLastOnPath(goToNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.2 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onPrevious = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const previousNode = nodePath.slice(0, nodeCurrentPosition);
    if (previousNode.length > 0) {
      setNodePath(previousNode);
      const previousNodeId = nodePath[nodePath.length - 2].toString();
      setIsLastOnPath(previousNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(previousNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onReset = () => {
    const resetNode = firstNodeId;
    setNodePath([resetNode]);
    const resetNodeId = firstNodeId.toString();
    setIsLastOnPath(resetNodeId);
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.25 * windowHeight;
    setTimeout(function () {
      if (!("parentIFrame" in window)) {
        scroller.scrollTo(resetNodeId, {
          duration: 300,
          delay: 25,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }
    }, 50);
    setTimeout(function () {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, -90);
      }
    }, 50);
  };

  const mapping = {
    chart: ({ nodes }) => <>{nodes}</>,
    node: ({
      content,
      disableDefaultOptionsButtons,
      extraContent,
      extraContentTitle,
      isEndNode,
      links,
      nodeId,
      nodeTitle,
      options,
    }) => (
      <>
        <Node
          disableDefaultOptionsButtons={disableDefaultOptionsButtons}
          extraContent={extraContent}
          extraContentTitle={extraContentTitle}
          isEndNode={isEndNode}
          isFirstOnPath={firstNodeId === nodeId}
          isLastOnPath={isLastOnPath === nodeId}
          isOnPath={nodePath.indexOf(nodeId) !== -1}
          key={nodeId}
          links={links}
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          onGoTo={onGoTo}
          onNext={onNext}
          onPrevious={onPrevious}
          onReset={onReset}
          options={options}
          language="nl"
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
        >
          {content}
        </Node>
      </>
    ),
    modalContent: ({ trigger, title, content }) => (
      <ModalContent trigger={trigger} title={title} content={content} />
    ),
    diagram: ({}) => <></>,
    alert: ({ type, message }) => (
      <Alert message={message} type={type} showIcon style={{ margin: "0" }} />
    ),
    button: ({ text, type, ghost, to, disabled }) => (
      <Paragraph>
        <Button type={type} ghost={ghost} disabled={disabled}>
          <Link to={to}>{text}</Link>
        </Button>
      </Paragraph>
    ),
    linkExternal: ({ text, to }) => (
      <Paragraph>
        <a href={to} target="_blank" rel="noopener norefferer">
          <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> {text}{" "}
          <span style={{ fontSize: "9px" }}>(opent in een nieuw venster)</span>
        </a>
      </Paragraph>
    ),
    carousel: ({ slides }) => (
      <Carousel
        autoplay
        style={{
          height: "400px",
          backgroundColor: "#364d79",
          textAlign: "center",
        }}
        effect="fade"
      >
        {slides}
      </Carousel>
    ),
    divider: () => <Divider dashed style={{ margin: "12px 0" }} />,
    description: ({ items }) => (
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        {items}
      </Descriptions>
    ),
    descriptionItem: ({ label, text }) => (
      <Descriptions.Item label={label} span={3}>
        {text}
      </Descriptions.Item>
    ),
    list: ({ items, header }) => (
      <List
        size="small"
        header={<Text strong>{header}</Text>}
        bordered
        items={items}
      />
    ),
    listAction: ({ header, items, item, children }) => (
      <ContentListAction
        size="small"
        header={<Text strong>{header}</Text>}
        bordered
        items={items}
        item={item}
        children={children}
      />
    ),
    paragraph: ({ children, text, ellipsis }) => (
      <Paragraph ellipsis={ellipsis}>
        {text} {children}
      </Paragraph>
    ),
    result: ({ title, status }) => <Result title={title} status={status} />,
    title: ({ title, level }) => <Title level={level}>{title}</Title>,
    text: ({
      text,
      type,
      code,
      copyable,
      disabled,
      ellipsis,
      mark,
      keyboard,
      underline,
      strong,
      style,
    }) => (
      <Text
        type={type}
        code={code}
        copyable={copyable}
        disabled={disabled}
        ellipsis={ellipsis}
        mark={mark}
        keyboard={keyboard}
        underline={underline}
        strong={strong}
      >
        {text}
      </Text>
    ),
    image: ({ src, alt }) => (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{ height: "400px", margin: "0 auto" }}
        />
      </div>
    ),
    ltaBloedRisico: () => <SelectBloedRisico />,
    ltaTromboRisico: () => <TableTromboRisico />,
    abcdeApproach: () => <ABCDEApproach />,
    IVaccess: () => <IVaccess />,
    Prevalentie: () => <Prevalentie />,
    OptionsSelectComponent: ({ steps, title }) => (
      <OptionsSelectComponent steps={steps} title={title} onNext={onNext} />
    ),
    OptionsItemsPointsComponent: ({
      steps,
      cutoffValue,
      nodeLinkOne,
      nodeLinkOneLabel,
      nodeLinkTwo,
      nodeLinkTwoLabel,
      title,
    }) => (
      <OptionsItemsPointsComponent
        steps={steps}
        cutoffValue={cutoffValue}
        nodeLinkOne={nodeLinkOne}
        nodeLinkOneLabel={nodeLinkOneLabel}
        nodeLinkTwo={nodeLinkTwo}
        nodeLinkTwoLabel={nodeLinkTwoLabel}
        onNext={onNext}
        title={title}
      />
    ),
    ContentLinkExternalFk: ({ title, url }) => (
      <ContentLinkExternalFk title={title} url={url} />
    ),
    ContentLinkExternalKf: ({ title, url }) => (
      <ContentLinkExternalKf title={title} url={url} />
    ),
    ContentLinkExternalRldb: ({ title, url }) => (
      <ContentLinkExternalRldb title={title} url={url} />
    ),
    ContentLinkExternalFNT: ({ title, url }) => (
      <ContentLinkExternalFNT title={title} url={url} />
    ),
    // LTA
    modalContent: ({ trigger, title, content }) => (
      <ModalContent trigger={trigger} title={title} content={content} />
    ),
    overview: ({}) => <Overview />,
    contentModalWithButtons: ({ trigger, title, content, links }) => (
      <ContentModalWithButtons
        trigger={trigger}
        title={title}
        content={content}
        onNext={onNext}
        links={links}
      />
    ),
  };

  return (
    <Xwrapper>
      <Row type="flex" className="chart-basic-collapse">
        <ReactFromJSON entry={entry} mapping={mapping} />
        <div id="bottom"></div>
      </Row>
    </Xwrapper>
  );
}

import React from "react";
import { Typography, Popover, Tag } from "antd";
import { ExportOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ContentLinkExternalFk = ({ title, url }) => {
  return (
    <>
      <Popover
        color="#777c00"
        placement="topRight"
        content={
          <div>
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: "#fff",
                marginBottom: "24px",
                textDecoration: "underline",
                textTransform: "capitalize",
              }}
            >
              <span style={{ fontSize: "14px" }}>
                Farmacotherapeutisch Kompas {">"} Geneesmiddelen {">"} <br />
              </span>
              {title}
            </a>
            <br />
            <Text style={{ fontSize: "11px", color: "#fff" }}>
              <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> opent
              in een nieuw venster
            </Text>
          </div>
        }
        trigger="hover"
      >
        <Tag style={{ margin: "0" }}>
          <Text
            style={{
              textDecoration: "underline",
              textDecorationColor: "#777c00",
              textTransform: "capitalize",
            }}
          >
            <img
              src="/img/favicon-fk.png"
              alt="Favicon FK"
              width={16}
              style={{ marginRight: "8px", top: "-2px", position: "relative" }}
            />
            {"> "}
            {title}
            <ExportOutlined
              style={{
                transform: "rotate(-90deg)",
                color: "#777c00",
                marginLeft: "2px",
              }}
            />
          </Text>
        </Tag>
      </Popover>
    </>
  );
};

export default ContentLinkExternalFk;

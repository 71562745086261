import React from "react";
import { Alert, Typography, Button, notification } from "antd";
import { IEView } from "react-device-detect";

const NotifyIE = () => {
  return (
    <IEView>
      <Alert
        message="Beste Internet Explorer gebruiker"
        description="Deze website is interactief en werkt daarom het beste met een moderne internet browser. U gebruikt nu een versie van Internet Explorer. Wij raden aan een moderne browser zoals Google Chrome, Mozilla FireFox of Apple Safari te gebruiken. Op deze manier kunnne wij het u het beste van dienst zijn en kunt u gebruik maken van alle interactieve features op deze website."
        type="warning"
        showIcon
        closable
      />
    </IEView>
  );
};

export default NotifyIE;

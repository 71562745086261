import React from "react";
import { Card, Col, Button, Tooltip, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import LinksButtonsActive from "../links/button/LinksButtonsActive";
import NodeArrowAnchors from "./components/NodeArrowAnchors";

export default function NodeCardOnPath({
  nodeId,
  nodeTitle,
  children,
  onNodeGoTo,
  onNext,
  links,
  linksActive,
  span,
  offset,
  language,
}) {
  const textDivider = language === "nl" ? "Geselecteerd" : "Selected";
  const textButtonEdit = language === "nl" ? "Wijzigen" : "Edit";
  const textButtonEditTooltip =
    language === "nl" ? "Wijzig deze stap" : "Edit this node";

  return (
    <Col
      id={nodeId}
      key={nodeId}
      span={span}
      offset={offset}
      className="node-wrapper"
    >
      <Card
        title={nodeTitle}
        size="small"
        style={{
          border: "3px solid #40c057",
          borderRadius: "4px",
          boxShadow:
            "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
        }}
        extra={
          <>
            <Tooltip placement="top" title={textButtonEditTooltip}>
              <Button size="small" onClick={onNodeGoTo}>
                <EditOutlined /> {textButtonEdit}
              </Button>
            </Tooltip>
          </>
        }
      >
        {children}
        <Divider
          orientation="left"
          plain
          style={{
            margin: "16px 0 10px 0",
            fontSize: "10px",
            borderColor: "#40c057",
          }}
        >
          {textDivider}:
        </Divider>
        <LinksButtonsActive
          links={links}
          linksActive={linksActive}
          onNext={onNext}
        />
      </Card>
      <NodeArrowAnchors nodeId={nodeId} />
    </Col>
  );
}

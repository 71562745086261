import React from "react";
import { Alert, Divider, List, Typography } from "antd";
const Title = Typography.Title;

const Overview = () => (
  <>
    <Title level={3}>Overzicht ingrepen</Title>
    <List size="small" bordered>
      <List.Item>Neurochirurgische operaties</List.Item>
      <List.Item>Spinale chirurgie</List.Item>
      <List.Item>Bepaalde oogheelkundige operaties</List.Item>
      <List.Item>Bepaalde KNO-operaties</List.Item>
      <List.Item>Bepaalde urologische ingrepen</List.Item>
    </List>
    <Divider />
    <Title level={3}>Overzicht KNO-operaties</Title>
    <List size="small" bordered>
      <List.Item>
        Laryngologie: poliklinische injectie-augmentatie, microlarynxchirurgie
        dilatatie subglottische stenose
      </List.Item>
      <List.Item>
        Oorchirurgie: myringoplastiek/middenoorinspectie, sanering chronische
        ontsteking/cholesteatoom, cochleair implantaat, stapedectomie,
        translabyrinthair brughoek
      </List.Item>
    </List>
    <Divider />
    <Title level={3}>Urologische-operaties</Title>
    <List size="small" bordered>
      <List.Item>
        TUR blaas, TUR prostaat, blaashalsincisie, Sachse-behandeling
        urethrastrictuur, ureterorenoscopie, blaassteenlithotrypsie, Sectio
        alta, Millin-open prostatectomie, nefro-ureterectomie,
        ureter-re-implantatie, diverticulectomie, pyelumplastiek, partiële
        nefrectomie
      </List.Item>
    </List>
    <Divider />
    <Alert
      message="Bij cataractchirurgie onder lokale druppelanesthesie hoeft TAR niet te worden onderbroken"
      type="info"
      showIcon
    />
  </>
);

export default Overview;

import React from "react";
import { Drawer, Button, Typography } from "antd";

const { Text } = Typography;

class ModalContent extends React.Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Text
          type="link"
          onClick={this.showDrawer}
          style={{ paddingLeft: "3px" }}
        >
          {this.props.trigger}
        </Text>
        <Drawer
          title={this.props.title}
          visible={this.state.visible}
          onClose={this.onClose}
          onCancel={this.onClose}
          drawerStyle={{ backgroundColor: "white" }}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Sluiten
            </Button>,
          ]}
        >
          {this.props.content}
        </Drawer>
      </>
    );
  }
}

export default ModalContent;

import { Divider, Card, Row, Col, Icon, Tag } from "antd";
import SelectPerson from "../../../../src/components/content/SelectPerson";
import { ExportOutlined } from "@ant-design/icons";
import * as React from 'react';
export default {
  Divider,
  Card,
  Row,
  Col,
  Icon,
  Tag,
  SelectPerson,
  ExportOutlined,
  React
};
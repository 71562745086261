import { Link } from "gatsby";
import { Divider, Button, Breadcrumb, Alert, Row, Col } from "antd";
import { RightOutlined } from "@ant-design/icons";
import NotifyIE from "../../../../src/components/NotifyIE.js";
import * as React from 'react';
export default {
  Link,
  Divider,
  Button,
  Breadcrumb,
  Alert,
  Row,
  Col,
  RightOutlined,
  NotifyIE,
  React
};
import React from "react";
import ButtonWithIndicator from "./ButtonWithIndicator";

export default function LinksButtons({ links, onNext, disabled, size }) {
  return links.link.map((item) => {
    return (
      <ButtonWithIndicator
        key={item.nodeId}
        onNext={onNext}
        k={item.nodeId}
        v={item.text}
        disabled={disabled}
        size={size}
      />
    );
  });
}

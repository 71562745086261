import { Avatar, List, Icon, Typography, Divider } from "antd";
import ListUrlAction from "../../../../src/components/ListUrlAction";
import * as React from 'react';
export default {
  Avatar,
  List,
  Icon,
  Typography,
  Divider,
  ListUrlAction,
  React
};
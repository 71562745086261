import React from "react";
import NodeCardActiveWithGrid from "../../node/NodeCardActiveWithGrid.js";
import NodeCardEndWithGrid from "../../node/NodeCardEndWithGrid";
import NodeCardOnPathWithGrid from "../../node/NodeCardOnPathWithGrid";
import NodeCardWithGrid from "../../node/NodeCardWithGrid";

export default function Node({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  isEndNode,
  isLastOnPath,
  isOnPath,
  language,
  links,
  linksActive,
  nodeId,
  nodeTitle,
  offset,
  onGoTo,
  onNext,
  onReset,
  options,
  showAllNodes,
  span,
  startDemo,
  useStartDemoProp,
}) {
  const onNodeGoTo = () => onGoTo(nodeId);

  if (!isOnPath) {
    return (
      <NodeCardWithGrid
        children={children}
        isLastOnPath={isLastOnPath}
        language={language}
        links={links}
        linksActive={linksActive}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        offset={offset}
        onNext={onNext}
        onNodeGoTo={onNodeGoTo}
        showAllNodes={showAllNodes}
        span={span}
      />
    );
  } else if (!isLastOnPath) {
    return (
      <NodeCardOnPathWithGrid
        children={children}
        isLastOnPath={isLastOnPath}
        language={language}
        links={links}
        linksActive={linksActive}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        offset={offset}
        onNext={onNext}
        onNodeGoTo={onNodeGoTo}
        span={span}
      />
    );
  } else if (isEndNode) {
    return (
      <NodeCardEndWithGrid
        children={children}
        extraContent={extraContent}
        language={language}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        offset={offset}
        onReset={onReset}
        span={span}
      />
    );
  } else {
    return (
      <NodeCardActiveWithGrid
        children={children}
        disableDefaultOptionsButtons={disableDefaultOptionsButtons}
        extraContent={extraContent}
        language={language}
        links={links}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        offset={offset}
        onNext={onNext}
        onReset={onReset}
        options={options}
        span={span}
        startDemo={startDemo}
        useStartDemoProp={useStartDemoProp}
      />
    );
  }
}

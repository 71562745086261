import React from "react";

import {
  Steps,
  Button,
  Badge,
  Card,
  Row,
  Col,
  Divider,
  Tag,
  Typography,
} from "antd";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

const ButtonGroup = Button.Group;
const { Title } = Typography;
const Step = Steps.Step;

const steps = [
  {
    title: "Therapietrouw",
    content:
      "<ul><li>Herhaalde instructie aan patiënten over belang van therapietrouw, vooral bij DOACs belangrijk omdat dit niet via labcontrole plaatsvindt.</li><li>Overweeg starten baxterbehandeling ter bevordering therapietrouw.</li></ul>",
  },
  {
    title: "Bijwerkingen",
    content:
      "<p>Vraag gericht naar bijwerkingen, afhankelijk van veronderstelde relatie met type anticoagulans (bijvoorbeeld dyspepsie): overweeg om tijdens de maaltijd in te nemen of te switchen naar ander anticoagulans (andere DOAC, VKA).</p>",
  },
  {
    title: "Dosering",
    content:
      '<p>Controleer of juiste DOAC dosering nog altijd wordt gebruikt (Zie Farmacotherapeutisch Kompas via <a href="/voorschrijven-en-follow-up/1-overzicht-doacs-vka-tars/">Overzicht DOACs, VKA, TARs</a>)</p>',
  },
  {
    title: "Trombo-embolie",
    content:
      "<ul><li>Beoordeel of indicatie nog geldig is</li><li>Vraag naar opgetreden trombo-embolische complicaties.</li><li>Herbeoordeel bij atriumfibrilleren (andere) trombotische risicofactoren (bijvoorbeeld cholesterol, bloeddruk), vooral bij trombo-embolische complicatie ondanks adequate dosering van anticoagulantia en overleg met deskundig medisch specialist.</li></ul>",
  },
  {
    title: "Bloeding",
    content:
      "<ul><li>Benadruk belang vaststellen van tijdstip van herstart anticoagulantia en erna therapietrouw, juist na klinisch relevante niet-majeure bloedingen die een (grote) impact kunnen hebben op kwaliteit van leven en therapietrouw.</li><li>Beoordeel en pak potentieel beïnvloedbare risicofactoren op een bloeding aan, zoals RR > 160 mm Hg, gebruik NSAIDs/TARs, overmatig alcoholgebruik, diabetische retinopathie, nierfalen, leverenzymstoornis of leverinsufficiëntie , onderliggend lijden zoals maligniteit (cave trombopenie), interacterende geneesmiddelen</li></ul>",
  },
  {
    title: "Interacties",
    content:
      '<ul><li>Zorg voor een volledig medicatie overzicht</li><li>Check interacties met andere geneesmiddelen, zie elektronisch patiëntendossier of voor de belangrijkste interacties het <a href="https://www.farmacotherapeutischkompas.nl" target="_blank">Farmacotherapeutisch Kompas</a> of Tabel 3 in <a href="https://academic.oup.com/eurheartj/article/39/16/1330/4942493" target="_blank">Steffel 2018</a>. Let op effect van staken van interacterende geneesmiddelen. </li></ul>',
  },
  {
    title: "Geplande operaties / ingrepen",
    content:
      "<p>De (tand)arts die de operatie of ingreep doet is verantwoordelijk voor het periprocedurele beleid van de antitrombotica.</p>",
  },
  {
    title: "Controle nierfunctie",
    content:
      "<ul><li>Minimaal jaarlijks bij jaarcontrole</li><li>Bij leeftijd boven de 75 jaar (vooral bij dabigatran) of kwetsbaarheid overweeg 2x per jaar, bij eGFR &lt;60 ml/min/1,73 m2 vaker</li><li>Overweeg bij intercurrente ziekten die van invloed zijn op nierfunctie, bijvoorbeeld (dreigende) dehydratie door gastro-enteritis, extra nierfunctiecontrole; dehydratie geeft vaak tijdelijke nierfunctiestoornissen en kan daarmee reden zijn voor tijdelijke dosisreductie van DOACs</li><li>DOAC activiteit kan worden gemeten met specifieke assays die in meeste gevallen regionaal beschikbaar zijn, zeker in een expertisecentrum.</li></ul>",
  },
  {
    title: "Veelgestelde vragen",
    content:
      '<p>Voor een overzicht van de veelvoorkomende vragen van patiënten, zie <a href="/communicatie-met-patienten/4-veelgestelde-vragen-over-doacs/">veelgestelde vragen over DOACs</a>.</p>',
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (current) => {
    this.setState({ current });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  reset() {
    const current = (this.state.current = 0);
    this.setState({ current });
  }

  render() {
    const { current } = this.state;

    return (
      <Card>
        <Row>
          <Col
            xs={3}
            sm={3}
            md={7}
            lg={9}
            xl={8}
            style={{ backgroundColor: "#fafafa", padding: "0px 0 12px 12px" }}
          >
            <Badge.Ribbon text="Items">
              <Steps
                current={current}
                onChange={this.onChange}
                size="small"
                direction="vertical"
                style={{ paddingTop: "25px" }}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Badge.Ribbon>
          </Col>
          <Col
            xs={21}
            sm={21}
            md={17}
            lg={15}
            xl={16}
            style={{ paddingLeft: "25px" }}
          >
            <div className="steps-content">
              <Title level={3} style={{ marginTop: "20px" }}>
                {steps[current].title}
              </Title>
              {ReactHtmlParser(steps[current].content)}
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span="24">
            <Divider />
            <ButtonGroup>
              {current > 0 && (
                <Button onClick={() => this.reset()}>
                  <DoubleLeftOutlined />
                </Button>
              )}
              {current === 0 && (
                <Button onClick={() => this.reset()} disabled>
                  <DoubleLeftOutlined />
                </Button>
              )}
              {current > 0 && (
                <Button type="primary" onClick={() => this.prev()}>
                  <LeftOutlined />
                  Terug
                </Button>
              )}
              {current === 0 && (
                <Button type="primary" onClick={() => this.prev()} disabled>
                  <LeftOutlined />
                  Terug
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => this.next()}>
                  Volgende
                  <RightOutlined />
                </Button>
              )}
              {current > steps.length - 1 && (
                <Button type="primary" onClick={() => this.next()}>
                  Bla
                  <RightOutlined />
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Stepper;

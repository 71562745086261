import React from "react";

import {
  Alert,
  Row,
  Col,
  Divider,
  Card,
  Select,
  Descriptions,
  Typography,
} from "antd";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    title: "Apixaban",
    merknaam: "Eliquis ®",
    tmax: "3-4 uur",
    thalf: "8-15 uur",
    plasma: "87%",
    renaleklaring: "25%",
    interactie: "P-gp, CYP3A4",
    effect: "Niet gerapporteerd",
  },
  {
    index: 1,
    title: "Dabigatran",
    merknaam: "Pradaxa ®",
    tmax: "1,5-2 uur",
    thalf: "12-17 uur",
    plasma: "35%",
    renaleklaring: "80%",
    interactie: "P-gp",
    effect: "Resorptie wordt vertraagd",
  },
  {
    index: 2,
    title: "Edoxaban",
    merknaam: "Lixiana ®",
    tmax: "1-2 uur",
    thalf: "9-10 uur",
    plasma: "40-59%",
    renaleklaring: "35-39%",
    interactie: "P-gp (CYP3A4)",
    effect: "Geen",
  },
  {
    index: 3,
    title: "Rivaroxaban",
    merknaam: "Xarelto ®",
    tmax: "2 uur",
    thalf: "5-9 uur *",
    plasma: "92-95%",
    renaleklaring: "33%",
    interactie: "P-gp, CYP3A4",
    effect: "Nodig voor resorptie bij een dosis >10mg",
    alert: "* Bij ouderen T½ is 11-13 uur",
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <Card>
        <Row>
          <Col xs={24}>
            <Title level={4}>DOAC:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              autoFocus
              defaultValue="Apixaban"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een DOAC.."
            >
              {steps.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Informatie:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Merknaam">
                {steps[current].merknaam}
              </Descriptions.Item>
              <Descriptions.Item label="Tijd tot maximale effect (T max)">
                {steps[current].tmax}
              </Descriptions.Item>
              <Descriptions.Item label="Halfwaarde tijd (T½)">
                {steps[current].thalf}
              </Descriptions.Item>
              <Descriptions.Item label="Plasma eiwitbinding">
                {steps[current].plasma}
              </Descriptions.Item>
              <Descriptions.Item label="Renale klaring">
                {steps[current].renaleklaring}
              </Descriptions.Item>
              <Descriptions.Item label="Interactie met medicatie via">
                {steps[current].interactie}
              </Descriptions.Item>
              <Descriptions.Item label="Effect van voedsel">
                {steps[current].effect}
              </Descriptions.Item>
            </Descriptions>
            {steps[current].alert ? (
              <Alert
                message={steps[current].alert}
                type="info"
                showIcon
                style={{ margin: "8px 0" }}
              />
            ) : null}
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Stepper;

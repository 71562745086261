import React from "react";
import { Typography, Popover, Tag } from "antd";
import { ExportOutlined } from "@ant-design/icons";
const { Text } = Typography;

const ContentLinkExternalAcuteBoekje = ({ title, url }) => {
  return (
    <>
      <Popover
        color="#882941"
        placement="topRight"
        content={
          <div>
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: "#fff",
                marginBottom: "24px",
                textDecoration: "underline",
              }}
            >
              <img
                src="/img/logoAcuteBoekje.png"
                alt="Logo Acute Boekje"
                width={48}
                style={{ marginRight: "8px", top: "-2px" }}
              />
              <span style={{ fontSize: "14px" }}>Het Acute Boekje {"> "}</span>
              {title}
            </a>
            <br />
            <Text style={{ fontSize: "11px", color: "#fff" }}>
              <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> opent
              in een nieuw venster
            </Text>
          </div>
        }
        trigger="hover"
      >
        <Tag style={{ margin: "0" }}>
          <Text
            style={{
              textDecoration: "underline",
              textDecorationColor: "#882941",
            }}
          >
            <ExportOutlined
              style={{
                transform: "rotate(-90deg)",
                color: "#882941",
                marginLeft: "2px",
              }}
            />{" "}
            {title}
          </Text>
        </Tag>
      </Popover>
    </>
  );
};

export default ContentLinkExternalAcuteBoekje;

import React from "react";

import {
  Steps,
  Button,
  Badge,
  Card,
  Row,
  Col,
  Divider,
  Tag,
  Typography,
} from "antd";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

const ButtonGroup = Button.Group;
const { Title } = Typography;
const Step = Steps.Step;
const steps = [
  {
    title: "Therapietrouw",
    content:
      "<ul><li>Herhaalde instructie aan patiënten over belang van therapietrouw, belangrijk omdat dit niet via lab controle plaatsvindt.</li><li>Overweeg starten medicatierol ter bevordering therapietrouw</li></ul>",
  },
  {
    title: "Bijwerkingen",
    content:
      "<p>Vraag gericht naar bijwerkingen, vooral bloedingen en maagklachten.</p>",
  },
  {
    title: "Dosering",
    content:
      "<p>In geval van Dual Antiplatelet Therapy (DAPT) of soms tijdelijk ‘triple’ therapie (DAPT i.c.m. antistolling) is het van belang om de geïndiceerde behandelduur te checken</p>",
  },
  {
    title: "Trombo-embolie",
    content:
      "<ul><li>Beoordeel of indicatie nog geldig is</li><li>Herbeoordeel trombotische risicofactoren</li><li>Vraag naar opgetreden trombo-embolische complicaties</li></ul>",
  },
  {
    title: "Bloeding",
    content:
      "<ul><li>Benadruk belang therapietrouw, juist bij klinisch relevante niet-majeure bloedingen die een (grote) impact kunnen hebben op kwaliteit van leven en therapietrouw.</li><li>Beoordeel en pak potentieel beïnvloedbare risicofactoren op een bloeding aan, zoals RR systolisch > 160 mm Hg, gebruik NSAIDs/andere TAR, overmatig alcoholgebruik, diabetische retinopathie, nierfalen, leverenzymstoornis of leverinsufficiëntie , onderliggend lijden zoals maligniteit (cave trombopenie), interacterende medicatie, indicatiestelling voor start protonpompremmer ter preventie.</li></ul>",
  },
  {
    title: "Interacties",
    content:
      "<ul><li>Zorg voor een volledig medicatie overzicht</li><li>Check interacties met andere geneesmiddelen, met name anticoagulantia; zie elektronisch patiëntendossier of voor de belangrijkste interacties het Farmacotherapeutisch Kompas.</li></ul>",
  },
  {
    title: "Geplande operaties / ingrepen",
    content:
      '<ul><li>De (tand)arts die de operatie of ingreep doet is verantwoordelijk voor het peri-procedurele beleid van de antitrombotica </li><li>Zie tevens de richtlijn <a href="/literatuur">Antitrombotisch Beleid</a></li></ul>',
  },
  {
    title: "Controle nierfunctie, Hb en leverfunctie",
    content:
      "<ul><li>Dit is bij TAR-behandeling niet standaard nodig</li><li>Wel kan periodieke Hb-controle zinvol zijn ter controle van occult/sluimerend bloedverlies en kan een verminderende nierfunctie een aanwijzing zijn voor een verhoogd bloedingsrisico</li></ul>",
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (current) => {
    this.setState({ current });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  reset() {
    const current = (this.state.current = 0);
    this.setState({ current });
  }

  render() {
    const { current } = this.state;

    return (
      <Card>
        <Row>
          <Col
            xs={3}
            sm={3}
            md={7}
            lg={9}
            xl={8}
            style={{ backgroundColor: "#fafafa", padding: "0px 0 12px 12px" }}
          >
            <Badge.Ribbon text="Items">
              <Steps
                current={current}
                onChange={this.onChange}
                size="small"
                direction="vertical"
                style={{ paddingTop: "25px" }}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Badge.Ribbon>
          </Col>
          <Col
            xs={21}
            sm={21}
            md={17}
            lg={15}
            xl={16}
            style={{ paddingLeft: "25px" }}
          >
            <div className="steps-content">
              <Title level={3} style={{ marginTop: "20px" }}>
                {steps[current].title}
              </Title>
              {ReactHtmlParser(steps[current].content)}
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span="24">
            <Divider />
            <ButtonGroup>
              {current > 0 && (
                <Button onClick={() => this.reset()}>
                  <DoubleLeftOutlined />
                </Button>
              )}
              {current === 0 && (
                <Button onClick={() => this.reset()} disabled>
                  <DoubleLeftOutlined />
                </Button>
              )}
              {current > 0 && (
                <Button type="primary" onClick={() => this.prev()}>
                  <LeftOutlined />
                  Terug
                </Button>
              )}
              {current === 0 && (
                <Button type="primary" onClick={() => this.prev()} disabled>
                  <LeftOutlined />
                  Terug
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => this.next()}>
                  Volgende
                  <RightOutlined />
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Stepper;

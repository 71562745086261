import React from "react";
import { navigate } from "gatsby";
import Recaptcha from "react-google-recaptcha";
import { Typography, Divider, message, Alert, Comment, Avatar } from "antd";

const { Paragraph } = Typography;

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = (value) => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
    this.setState({
      visible: false,
    });
    message.success("Uw bericht is verzonden");
  };

  render() {
    return (
      <>
        <Comment
          author={<span>Federatie Medisch Specialisten</span>}
          avatar={
            <Avatar
              src="/img/logo-fms.png"
              alt="Federatie  Medisch Specialisten"
            />
          }
          content={
            <p>
              Heeft u een vraag of een opmerking over de inhoud van de LTA
              antistollingszorg? We horen dat graag. U kunt uw vraag of
              opmerking doorgeven via onderstaand contactformulier. We nemen dan
              contact met u op.
            </p>
          }
        />
        <Divider />
        <form
          name="Contactformulier LTA Antistollingszorg"
          method="post"
          action="/"
          data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={this.handleSubmit}
        >
          <noscript>
            <p>
              Dit formulier werkt niet zonder Javascript. Zet Javascript aan in
              uw browser.
            </p>
          </noscript>
          <p>
            <label>
              Uw naam:
              <br />
              <input
                type="text"
                className="ant-input"
                name="name"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              Uw e-mailadres:
              <br />
              <input
                type="email"
                className="ant-input"
                name="email"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              Organisatie:
              <br />
              <input
                type="text"
                className="ant-input"
                name="organisation"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              Functie:
              <br />
              <input
                type="text"
                className="ant-input"
                name="function"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              Uw bericht:
              <br />
              <textarea
                name="message"
                className="ant-input"
                onChange={this.handleChange}
                required
              />
            </label>
          </p>
          <Recaptcha
            ref="recaptcha"
            sitekey={RECAPTCHA_KEY}
            onChange={this.handleRecaptcha}
          />
          <Divider />
          <Paragraph>
            De informatie op deze website is met de grootst mogelijke
            zorgvuldigheid samengesteld. De auteurs en wetenschappelijke
            verenigingen sluiten iedere aansprakelijkheid voor de opmaak en de
            inhoud van de LTA, alsmede voor de gevolgen die de toepassing van de
            LTA in de patiëntenzorg mocht hebben, uit.
          </Paragraph>
          <Alert
            message="Door gebruikers verstrekte persoonsgegevens ten behoeve van het contactformulier op lta-antistollingszorg.nl worden vertrouwelijk behandeld. Gegevens worden zonder toestemming niet verstrekt aan derden."
            showIcon
            type="info"
          />
          <Divider />
          <button type="submit" className="ant-btn">
            Versturen
          </button>
        </form>
      </>
    );
  }
}

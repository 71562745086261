import { Divider, Tooltip, List, Card, Typography, Row, Col, Statistic, Popover, Button } from "antd";
import ModalContent from "../../../../src/components/modalContent.js";
import TableChadCalc from "../../../../src/components/content/TableChadCalc.js";
import * as React from 'react';
export default {
  Divider,
  Tooltip,
  List,
  Card,
  Typography,
  Row,
  Col,
  Statistic,
  Popover,
  Button,
  ModalContent,
  TableChadCalc,
  React
};
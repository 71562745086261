import React from "react";
import { Button, Modal, Typography } from "antd";

const { Link } = Typography;

class ModalContent extends React.Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Link onClick={this.showDrawer} style={{ paddingLeft: "3px" }}>
          {this.props.trigger}
        </Link>
        <Modal
          title={this.props.title}
          visible={this.state.visible}
          onOk={this.onClose}
          onCancel={this.onClose}
          style={{ zIndex: "30000" }}
          drawerStyle={{ backgroundColor: "white" }}
          centered
          footer={[
            <Button key="submit" type="primary" onClick={this.onClose}>
              Sluiten
            </Button>,
          ]}
        >
          {this.props.content}
        </Modal>
      </>
    );
  }
}

export default ModalContent;
